/** @jsx jsx */
import React from 'react'
import {jsx, Box, Container} from "theme-ui"
import {animated, config, useSpring} from "react-spring"
import {graphql, useStaticQuery} from "gatsby"
import Layout from "../components/Layout"
import Header from "../components/Header"
import Card from "../components/Card"

const Index = () => {
    const data = useStaticQuery(graphql`
    query {
      allPrismicProjects(sort: {fields: first_publication_date, order: DESC}) {
        edges {
          node {
            id
            data {
              title {
                text
              }
              images {
                image {
                    localFile {
                        childImageSharp {
                                fluid(maxWidth: 770) {
                                    presentationHeight,
                                    ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                    }
                }
              }
              content {
                text
              }
            }
            uid
          }
        }
      }
    }
  `)

    const fadeUpProps = useSpring({
        config: config.slow,
        delay: 600,
        from: {opacity: 0, transform: `translate3d(0, 30px, 0)`},
        to: {opacity: 1, transform: `translate3d(0, 0, 0)`},
    })

    const projects = data.allPrismicProjects.edges

    return (
        <Layout>
            <Header/>
            <Box as="main" variant="layout.main">
                <animated.div style={fadeUpProps}>
                    <Container
                        sx={{
                            mt: `-8rem`,
                            display: `grid`,
                            gridTemplateColumns: [`1fr`, `repeat(auto-fill, minmax(350px, 1fr))`],
                            gridColumnGap: 4,
                        }}
                    >
                        {projects.map((project, index) => {
                            return <Card key={project.node.uid} item={project.node} inGrid={true}/>
                        })}
                    </Container>
                </animated.div>
            </Box>
        </Layout>
    )
}

export default Index